import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Team from './pages/team/Team';
import Gift from './pages/gift/Gift';
import Shop from './pages/shop/Shop';
import ContactUs from './pages/contactus/ContactUs';


const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: 'team',
    element: <Team />,
  },
  {
    path: 'gift',
    element: <Gift />,
  },
  {
    path: 'shop',
    element: <Shop />,
  },
  {
    path: 'contact',
    element: <ContactUs />
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider  router={router}/>
  </React.StrictMode>
);

